import {
	Paper,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	LinearProgress,
	Chip,
} from '@mui/material';
import { addDays, getISOWeek, format, previousMonday } from 'date-fns';
import { useState, useMemo, useEffect } from 'react';
import {
	FunctionSchedule,
	FunctionScheduleBag,
} from '../../rest-models/rest-model';
import { EquipmentWithRank } from '../SchedulePage';
import { restServer } from '../../../services/AxiosConfiguration';
import { Comment, Lock } from '@mui/icons-material';

const DATEFMT = 'yyyy-MM-dd';

const DAY_COLORS = [
	'#ffccff',
	'#ffff99',
	'#ffcc99',
	'#ccffff',
	'#eaeaea',
] as const;

/**
 * sets an endDate 7 days from startDate
 * @param startDate
 * @returns a list of FunctionSchedule from startDate to endDate
 */
const getThisWeekSchedule = (startDate: Date) => {
	const endDate = addDays(startDate, 7);
	const result = restServer<FunctionSchedule[]>('views/function-schedule/', {
		params: {
			startDate: format(startDate, DATEFMT),
			endDate: format(endDate, DATEFMT),
		},
	});
	return result;
};

const getMonday = (date: Date): Date => {
	if (date.getDay() === 1) return date;
	else return previousMonday(date);
};

interface BagWithDate {
	date: Date;
	bags: FunctionScheduleBag[];
}

interface EquipmentWithDayBags {
	equipment: EquipmentWithRank;
	bagWithDate: BagWithDate[];
	canBeHidden: boolean;
}

export function WeekTab(props: {
	date: Date;
	changeDate: (newDate: Date) => void;
}) {
	const { date } = props;
	const [data, setData] = useState<EquipmentWithDayBags[]>([]);
	const [dataLoading, setDataLoading] = useState(false);

	const dates = useMemo(() => {
		const res = [getMonday(date)];
		for (let d = 1; d < 5; d++) {
			res.push(addDays(res[d - 1], 1));
		}
		return res;
	}, [date]);

	useEffect(() => {
		setDataLoading(true);
		const startDate = getMonday(date);

		getThisWeekSchedule(startDate).then(res => {
			setDataLoading(false);

			// all equipment
			const allEquipment = res.data
				.reduce<EquipmentWithRank[]>((acc, cur) => {
					// each day
					const candidates: EquipmentWithRank[] = [];
					cur.jsonData.template.row.forEach(item => {
						const fItem = acc.find(
							accItem => accItem.id === item.equipment?.id
						);
						if (fItem === undefined && item.equipment)
							candidates.push({ ...item.equipment, rank: item.rank });
					});
					return acc.concat(candidates);
				}, [])
				.sort((a, b) => a.rank - b.rank);

			// date range arr
			const dateRangeArr = Array.from({ length: 5 }, (_, i) =>
				format(addDays(startDate, i), 'yyyy-MM-dd')
			);

			// pair ranked equipment with bags
			const content: EquipmentWithDayBags[] = allEquipment.map(eq => {
				// for each day: hopefully starting monday
				const row = dateRangeArr.map<BagWithDate>(dt => {
					const current = res.data.find(p => p.date === dt);
					return {
						date: new Date(dt),
						bags:
							current !== undefined
								? current.jsonData.bags.filter(p => p.equipment.id === eq.id)
								: [],
					};
				});

				return {
					equipment: eq,
					canBeHidden: false,
					bagWithDate: row,
				};
			});

			setData(content);
		});
	}, [date]);

	return (
		<>
			<Paper sx={{ p: 2, minHeight: 800 }}>
				<Typography variant='body1'>
					{`Vecka ${getISOWeek(props.date)}`}
				</Typography>

				{dataLoading === true ? (
					<LinearProgress />
				) : data.length === 0 ? (
					'Ingen data'
				) : (
					<TableContainer sx={{ mb: 5 }}>
						<Table size='small' style={{ tableLayout: 'fixed' }}>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography fontWeight='bold' variant='subtitle1'>
											Utrustning
										</Typography>
									</TableCell>
									{dates.map(item => (
										<TableCell key={item.getDate()}>
											<Typography fontWeight='bold' variant='subtitle1'>
												{format(item, 'iiii dd')}
											</Typography>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((row, index) => (
									<FunctionTableRow
										key={row.equipment.id}
										row={row}
										changeDate={props.changeDate}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Paper>
		</>
	);
}

function FunctionTableRow(props: {
	row: EquipmentWithDayBags;
	changeDate: (newDate: Date) => void;
}) {
	const { row } = props;

	return (
		<TableRow>
			<Typography variant='body1' fontWeight='bold' component='td'>
				{row.equipment.name}
			</Typography>
			{row.bagWithDate.map((day, index) => (
				<TableCell
					key={day.date.toString()}
					sx={{ p: '2px', verticalAlign: 'top' }}
				>
					{day.bags.map(bag => (
						<FunctionRow
							key={bag.dropListID}
							boxItem={bag}
							dayColor={DAY_COLORS[index]}
							changeDate={() => {
								props.changeDate(day.date);
							}}
						/>
					))}
				</TableCell>
			))}
		</TableRow>
	);
}

function FunctionRow(props: {
	boxItem: FunctionScheduleBag;
	dayColor: string;
	changeDate: () => void;
}) {
	const bgColor =
		props.boxItem.locked === true ? 'rgba(0,0,0,0.2)' : props.dayColor;
	const textColor =
		props.boxItem.locked === true ? 'rgba(0,0,0, 0.33)' : '#000';

	return (
		<Box
			sx={{
				border: 1,
				borderColor: 'divider',
				p: '2px',
				backgroundColor: bgColor,
				cursor: 'grab',
				'.MuiTypography-root': {
					color: textColor,
				},
				'&:hover': {
					backgroundColor: t => t.palette.primary.main,
					'.MuiTypography-root': {
						color: t => t.palette.primary.contrastText,
					},
				},
			}}
			onClick={() => props.changeDate()}
		>
			<Typography
				variant='body1'
				fontWeight='bold'
				component={Box}
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				{props.boxItem.startTime} - {props.boxItem.endTime}
				{props.boxItem.locked === true && <Lock fontSize='small' />}
			</Typography>
			<Typography variant='body1'>{props.boxItem.description}</Typography>
			<Box sx={{ display: 'flex', gap: 1 }}>
				<Typography variant='body1' fontWeight='bold'>
					Personal
				</Typography>
				<Typography
					variant='body1'
					component={Box}
					fontStyle='italic'
					sx={{ display: 'flex' }}
				>
					{props.boxItem.staffAllocation.length === 0
						? '---'
						: props.boxItem.staffAllocation.map(st => (
								<>
									{st.numAssignment && (
										<Chip label={st.numAssignment} size='small' />
									)}
									{`${st.abbrev} `}
								</>
						  ))}
				</Typography>
			</Box>
			{props.boxItem.comment && (
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Comment fontSize='small' />
					<Typography variant='body1'>{props.boxItem.comment}</Typography>
				</Box>
			)}
		</Box>
	);
}
