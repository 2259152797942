import { useEffect, useMemo, useState } from 'react';
import {
	FunctionTemplate,
	FunctionTemplateRow,
	TemplateWeekDay,
} from '../../../rest-models/rest-functions';
import { GenericFormProp, NameTextField } from '../Common';
import {
	Autocomplete,
	Box,
	Button,
	Divider,
	FormLabel,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	Add,
	ArrowDropDown,
	ArrowDropUp,
	Check,
	Clear,
	Delete,
	Edit,
	Remove,
	Replay,
	Warning,
} from '@mui/icons-material';
import { restServer } from '../../../../services/AxiosConfiguration';
import { Certificate, Equipment } from '../../../rest-models/rest-model';

const DAY_COLORS = [
	'#ffccff',
	'#ffff99',
	'#ffcc99',
	'#ccffff',
	'#eaeaea',
] as const;

const getEquipment = async () => {
	const res = await restServer.get<Equipment[]>('/equipment', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

const getCertificates = async () => {
	const res = await restServer.get<Certificate[]>('/views/certificate', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

export function FunctionForm(props: GenericFormProp<FunctionTemplate>) {
	const {
		selected,
		formState,
		setFormState,
		setFormChanged,
		shouldResetForm,
		setShouldResetForm,
	} = props;

	const [equipment, setEquipment] = useState<Equipment[]>([]);
	useEffect(() => {
		getEquipment().then(setEquipment);
	}, []);

	const [certificates, setCertificates] = useState<Certificate[]>([]);
	useEffect(() => {
		getCertificates().then(setCertificates);
	}, []);

	const [editingRow, setEditingRow] = useState<number | null>(null);

	useEffect(() => {
		setEditingRow(null);
		setShouldResetForm(false);
	}, [setShouldResetForm, shouldResetForm]);

	return (
		<Box component='form' className='management-form-inner'>
			<Box display={'flex'} flexDirection={'column'} mb={2} mt={-1}>
				<NameTextField
					name={formState.name}
					setName={name => {
						setFormState({ ...formState, name: name });
						setFormChanged(true);
					}}
					disabled={selected.name === formState.name}
					reset={() => {
						setFormState({ ...formState, name: selected.name });
					}}
				/>
				<TextField
					variant='standard'
					label='Beskrivning'
					value={formState.description}
					onChange={event => {
						setFormState({
							...formState,
							description: event.target.value,
						});
						setFormChanged(true);
					}}
					InputProps={{
						endAdornment: (
							<Tooltip title={'Återställ'}>
								<span>
									<IconButton
										color='primary'
										disabled={selected.description === formState.description}
										onClick={() => {
											setFormState({
												...formState,
												description: selected.description,
											});
										}}
									>
										<Replay />
									</IconButton>
								</span>
							</Tooltip>
						),
					}}
				/>
			</Box>

			<FullGrid
				editingRow={editingRow}
				setEditingRow={setEditingRow}
				formState={formState}
				setFormState={setFormState}
				setFormChanged={setFormChanged}
				equipment={equipment}
				certificates={certificates}
			/>
		</Box>
	);
}

type FullGridProps = {
	editingRow: number | null;
	setEditingRow: (val: number | null) => void;
	formState: FunctionTemplate;
	setFormState: (val: FunctionTemplate) => void;
	setFormChanged: (val: boolean) => void;
	equipment: Equipment[];
	certificates: Certificate[];
};

function FullGrid(props: FullGridProps) {
	const {
		editingRow,
		setEditingRow,
		formState,
		setFormState,
		setFormChanged,
		equipment,
		certificates,
	} = props;

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(6, 1fr)',
				mb: 10,
				gridColumn: 'span 2',
			}}
		>
			<Typography variant='body1'>Utrustning</Typography>
			<Typography variant='body1'>Måndag</Typography>
			<Typography variant='body1'>Tisdag</Typography>
			<Typography variant='body1'>Onsdag</Typography>
			<Typography variant='body1'>Torsdag</Typography>
			<Typography variant='body1'>Fredag</Typography>
			<Divider sx={{ gridColumn: 'span 6', mb: 1 }} />
			{formState.row
				.sort((a, b) => a.rank - b.rank)
				.map((row, rowIdx) => (
					<Row
						row={row}
						setRow={val => {
							const copy = [...formState.row];
							copy[rowIdx] = val;
							setFormState({ ...formState, row: copy });
							setFormChanged(true);
						}}
						isEditing={editingRow === rowIdx}
						setIsEditing={val => {
							if (val) {
								setEditingRow(rowIdx);
							} else {
								setEditingRow(null);
							}
						}}
						isEditable={editingRow == null}
						setFormChanged={setFormChanged}
						deleteRow={() => {
							const copy = structuredClone(formState);
							copy.row.splice(rowIdx, 1);
							copy.row.forEach(
								(row: FunctionTemplateRow, idx: number) => (row.rank = idx)
							);
							setFormState(copy);
							setFormChanged(true);
						}}
						equipment={equipment}
						certificates={certificates}
						onMoveUp={() => {
							if (rowIdx === 0) {
								return;
							}
							const copy = structuredClone(formState);
							const temp = copy.row[rowIdx];
							copy.row[rowIdx] = copy.row[rowIdx - 1];
							copy.row[rowIdx - 1] = temp;
							copy.row.forEach(
								(row: FunctionTemplateRow, idx: number) => (row.rank = idx)
							);
							setFormState(copy);
							setFormChanged(true);
						}}
						onMoveDown={() => {
							if (rowIdx >= formState.row.length - 1) {
								return;
							}
							const copy = structuredClone(formState);
							const temp = copy.row[rowIdx];
							copy.row[rowIdx] = copy.row[rowIdx + 1];
							copy.row[rowIdx + 1] = temp;
							copy.row.forEach(
								(row: FunctionTemplateRow, idx: number) => (row.rank = idx)
							);
							setFormState(copy);
							setFormChanged(true);
						}}
						key={rowIdx}
					/>
				))}
			{!editingRow && (
				<Button
					startIcon={<Add />}
					onClick={() => {
						const copy = structuredClone(formState);
						copy.row.push({
							equipment: null,
							rank: copy.row.length,
							week: [],
						});
						setFormChanged(true);
						setFormState(copy);
						setEditingRow(copy.row.length - 1);
					}}
				>
					Lägg till rad
				</Button>
			)}
		</Box>
	);
}

type RowProps = {
	row: FunctionTemplateRow;
	setRow: (val: FunctionTemplateRow) => void;
	isEditing: boolean;
	setIsEditing: (val: boolean) => void;
	isEditable: boolean;
	setFormChanged: (val: boolean) => void;
	deleteRow: () => void;
	equipment: Equipment[];
	certificates: Certificate[];
	onMoveUp: () => void;
	onMoveDown: () => void;
};

function Row(props: RowProps) {
	const {
		row,
		setRow,
		isEditing,
		setIsEditing,
		isEditable,
		deleteRow,
		equipment,
		certificates,
		onMoveUp,
		onMoveDown,
	} = props;

	const editButtonSet = isEditable ? (
		<Box sx={{ height: 'min-content' }}>
			<Tooltip title={'Ändra rad'}>
				<IconButton
					onClick={() => {
						setIsEditing(true);
					}}
				>
					<Edit />
				</IconButton>
			</Tooltip>
			<Tooltip title={'Ta bort rad'}>
				<IconButton onClick={deleteRow}>
					<Delete />
				</IconButton>
			</Tooltip>
		</Box>
	) : (
		<></>
	);

	const standardView = (
		<>
			<Box
				key={row.equipment?.id ?? 0}
				display={'flex'}
				alignItems={'top'}
				gap={1}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					sx={{ fontSize: '.5em' }}
				>
					<IconButton onClick={onMoveUp}>
						<ArrowDropUp />
					</IconButton>
					<IconButton onClick={onMoveDown}>
						<ArrowDropDown />
					</IconButton>
				</Box>
				<Typography fontWeight='bold' variant='body1' mt={0.7}>
					{row.equipment?.name ?? 'Null'}
				</Typography>
				{editButtonSet}
			</Box>
			<Week week={row.week} />
		</>
	);

	return (
		<>
			{isEditing ? (
				<EditRow
					row={row}
					setRow={setRow}
					setIsEditing={setIsEditing}
					equipment={equipment}
					certificates={certificates}
				/>
			) : (
				standardView
			)}
			<Divider sx={{ gridColumn: 'span 6', my: 1 }} />
		</>
	);
}

type WeekProps = {
	week: TemplateWeekDay[];
};

function Week(props: WeekProps) {
	const { week } = props;

	const weekDays = useMemo(() => {
		const weekDays: TemplateWeekDay[][] = [[], [], [], [], []];

		for (const slot of week) {
			weekDays[slot.dayIndex].push(slot);
		}

		return weekDays;
	}, [week]);

	const timeSort = (a: TemplateWeekDay, b: TemplateWeekDay): number => {
		const startTimeDif = parseInt(a.startTime) - parseInt(b.startTime);
		if (startTimeDif !== 0) {
			return startTimeDif;
		}
		return a.rank - b.rank;
	};

	return (
		<>
			{weekDays.map((day, index) => (
				<Box key={index} display={'flex'} flexDirection={'column'} gap={1}>
					{day.sort(timeSort).map(slot => (
						<Box
							key={`${slot.dayIndex}-${slot.startTime}-${slot.endTime}-${slot.rank}`}
							sx={{ backgroundColor: DAY_COLORS[index], p: 1 }}
						>
							<Box display={'flex'} justifyContent={'space-between'}>
								<Typography fontWeight='bold'>
									{slot.startTime}-{slot.endTime}
								</Typography>
								<Typography fontWeight='bold'>
									{slot.minSlot}-{slot.maxSlot}
								</Typography>
							</Box>
							<Typography>{slot.description}</Typography>
						</Box>
					))}
				</Box>
			))}
		</>
	);
}

type IndexedSlot = TemplateWeekDay & {
	slotID: number;
};

type EditRowProps = {
	row: FunctionTemplateRow;
	setRow: (val: FunctionTemplateRow) => void;
	setIsEditing: (val: boolean) => void;
	equipment: Equipment[];
	certificates: Certificate[];
};

function EditRow(props: EditRowProps) {
	const { row, setRow, setIsEditing, equipment, certificates } = props;

	const weekDays = useMemo(() => {
		const weekDays: IndexedSlot[][] = [[], [], [], [], []];

		row.week.forEach((slot, slotIdx) => {
			weekDays[slot.dayIndex].push({ slotID: slotIdx, ...slot });
		});

		return weekDays;
	}, [row.week]);

	const timeSort = (a: TemplateWeekDay, b: TemplateWeekDay): number => {
		const startTimeDif = parseInt(a.startTime) - parseInt(b.startTime);
		if (startTimeDif !== 0) {
			return startTimeDif;
		}
		return a.rank - b.rank;
	};

	return (
		<Box
			display={'grid'}
			gridColumn={'span 6'}
			gridTemplateColumns={'repeat(6, 1fr)'}
		>
			<Box display={'flex'} alignItems={'top'}>
				<Box flexBasis={200}>
					<Autocomplete
						isOptionEqualToValue={(opt, value) => opt.id === value.id}
						renderInput={params => <TextField {...params} variant='standard' />}
						value={row.equipment}
						getOptionLabel={opt => opt.name}
						options={equipment}
						onChange={(_, val) => {
							const copy = structuredClone(row);
							copy.equipment = val;
							setRow(copy);
						}}
					/>
				</Box>
				<Tooltip sx={{ height: 'min-content' }} title={'Spara rad'}>
					<span>
						<IconButton
							onClick={() => setIsEditing(false)}
							disabled={row.equipment == null}
						>
							<Check />
						</IconButton>
					</span>
				</Tooltip>
			</Box>
			{weekDays.map((slots, dayIdx) => (
				<Box key={dayIdx} display={'flex'} flexDirection={'column'} gap={1}>
					{slots.sort(timeSort).map(slot => (
						<EditBox
							slot={slot}
							setSlot={val => {
								const copy = structuredClone(row);
								copy.week[val.slotID] = {
									dayIndex: val.dayIndex,
									rank: val.rank,
									startTime: val.startTime,
									endTime: val.endTime,
									certificates: val.certificates,
									minSlot: val.minSlot,
									maxSlot: val.maxSlot,
									description: val.description,
								};
								setRow(copy);
							}}
							onRemove={() => {
								const copy = structuredClone(row);
								copy.week.splice(slot.slotID, 1);
								copy.week.forEach(
									(slot: TemplateWeekDay, idx: number) => (slot.rank = idx)
								);
								setRow(copy);
							}}
							certificates={certificates}
							key={slot.slotID}
						/>
					))}

					<Button
						startIcon={<Add />}
						onClick={() => {
							const copy = structuredClone(row);
							copy.week.push({
								dayIndex: dayIdx,
								rank: weekDays[dayIdx].length,
								startTime: '',
								endTime: '',
								description: '',
								minSlot: 0,
								maxSlot: 1,
								certificates: [],
							});
							setRow(copy);
						}}
					>
						Lägg till tid
					</Button>
				</Box>
			))}
		</Box>
	);
}

type EditBoxProps = {
	slot: IndexedSlot;
	setSlot: (val: IndexedSlot) => void;
	certificates: Certificate[];
	onRemove: () => void;
};

function EditBox(props: EditBoxProps) {
	const { slot, setSlot, certificates, onRemove } = props;

	const setTime = (startTime: string, endTime: string) => {
		setSlot({ ...slot, startTime, endTime });
	};

	const amPmButtons = (
		<Box display={'flex'} justifyContent={'start'} gap={1}>
			<Button
				variant={'contained'}
				onClick={() => setTime('07:30', '12:00')}
				sx={{
					backgroundColor:
						slot.startTime === '07:30' ? 'primary.main' : '#0000',
					color: slot.startTime === '07:30' ? '#FFF' : '#1b1b1b',
					':hover': { color: '#FFF' },
				}}
			>
				Förmiddag
			</Button>
			<Button
				variant={'contained'}
				onClick={() => setTime('12:30', '16:00')}
				sx={{
					backgroundColor:
						slot.startTime === '12:30' ? 'primary.main' : '#0000',
					color: slot.startTime === '12:30' ? '#FFF' : '#1b1b1b',
					':hover': { color: '#FFF' },
				}}
			>
				Eftermiddag
			</Button>
		</Box>
	);

	return (
		<Box
			position={'relative'}
			display={'flex'}
			flexDirection={'column'}
			sx={{ backgroundColor: DAY_COLORS[slot.dayIndex], p: 1 }}
			key={`${slot.startTime}-${slot.endTime}`}
		>
			<FormLabel>Tid</FormLabel>
			{amPmButtons}
			<FormLabel>Beskrivning</FormLabel>
			<TextField
				multiline
				value={slot.description}
				onChange={event =>
					setSlot({ ...slot, description: event.target.value })
				}
			/>
			<FormLabel>Antal personal</FormLabel>
			<Box display={'flex'} alignItems={'center'} gap={0.5} width={'60%'}>
				<TextField
					value={String(slot.minSlot)}
					type='number'
					onChange={event =>
						setSlot({ ...slot, minSlot: Number(event.target.value) })
					}
				/>
				{' - '}
				<TextField
					value={String(slot.maxSlot)}
					type='number'
					onChange={event => {
						setSlot({ ...slot, maxSlot: Number(event.target.value) });
					}}
				/>
			</Box>
			<FormLabel>Körkort</FormLabel>
			<Box display={'flex'} flexDirection={'column'}>
				{slot.certificates.map((cert, certIdx) => (
					<Box display={'flex'} key={certIdx}>
						<Autocomplete
							isOptionEqualToValue={(opt, value) => opt.id === value.id}
							fullWidth
							renderInput={params => (
								<TextField {...params} variant='standard' />
							)}
							value={cert}
							getOptionLabel={opt => opt.name}
							options={certificates}
							onChange={(_, val) => {
								const copy = structuredClone(slot);
								copy.certificates[certIdx] = val;
								setSlot(copy);
							}}
						/>
						<Tooltip title='Ta bort körkort'>
							<IconButton
								onClick={() => {
									const copy = structuredClone(slot);
									copy.certificates.splice(certIdx, 1);
									setSlot(copy);
								}}
							>
								<Remove />
							</IconButton>
						</Tooltip>
					</Box>
				))}
				<Tooltip
					sx={{ width: 'min-content', alignSelf: 'center' }}
					title='Lägg till körkort'
					onClick={() => {
						const copy = structuredClone(slot);
						copy.certificates.push(null);
						setSlot(copy);
					}}
				>
					<IconButton>
						<Add />
					</IconButton>
				</Tooltip>
			</Box>
			<Box display={'flex'} flexDirection={'column'} gap={0.5}>
				{slot.certificates.some(c => c == null) && (
					<ErrorMessage message={'Minst ett körkort är tomt'} />
				)}
				{slot.certificates.some(
					(c, cIdx) =>
						c != null &&
						slot.certificates.some(
							(oc, ocIdx) => oc != null && ocIdx !== cIdx && c.id === oc.id
						)
				) && <ErrorMessage message={'Minst två körkort är samma'} />}
			</Box>
			<IconButton
				sx={{ position: 'absolute', right: 4, top: 4 }}
				onClick={onRemove}
			>
				<Clear />
			</IconButton>
		</Box>
	);
}

function ErrorMessage(props: { message: string }) {
	const { message } = props;

	return (
		<Typography
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: 1,
				backgroundColor: '#F035',
				p: 0.5,
				borderRadius: '.5em',
			}}
		>
			<Warning color='error' /> {message}
		</Typography>
	);
}
