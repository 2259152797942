import {
	Autocomplete,
	Box,
	Button,
	Chip,
	Divider,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { restServer } from '../../services/AxiosConfiguration';
import {
	FunctionTemplate,
	TemplateWeekDay,
} from '../rest-models/rest-functions';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Certificate,
	Equipment,
	FunctionSchedule,
	FunctionScheduleBag,
	StaffSchedule,
	StaffWithProfession,
} from '../rest-models/rest-model';
import { TList } from './management/Common';
import {
	Add,
	ChatOutlined,
	Clear,
	Lock,
	LockOpen,
	Replay,
	Save,
} from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
	DndContext,
	DragOverlay,
	useSensor,
	useSensors,
	MouseSensor,
	UniqueIdentifier,
	useDraggable,
	useDroppable,
} from '@dnd-kit/core';
import React from 'react';
import { format } from 'date-fns';
import { StaffNumAssignmentDialog } from './FunctionSchedule/StaffNumAssignmentDialog';
import { SnackbarContext } from '../../context/SnackbarProvider';
import { AxiosError } from 'axios';

type MKPTimeSlot = 'MORNING' | 'AFTERNOON' | 'FULLDAY' | 'UNAVAILABLE';

export type StaffWithLock = StaffWithProfession & {
	locked: boolean;
	unavailable: boolean;
	availableMorning: boolean;
	placedMorning: number;
	availableAfternoon: boolean;
	placedAfternoon: number;
};

type MKPStaff = {
	id: number;
	timeSlot: MKPTimeSlot;
	certificates: number[];
};

type MKPBag = {
	id: number;
	minSlot: number;
	maxSlot: number;
	timeSlot: MKPTimeSlot;
	requirements: number[];
	staffAllocation: MKPStaff[];
};

type MKPQuery = {
	bags: MKPBag[];
	staff: MKPStaff[];
};

type DraggingStaffData = {
	staffID: number;
	allocation: UniqueIdentifier | undefined;
};

type DropListData = {
	equipmentID: number;
	startTime: string;
	endTime: string;
	allocatedStaff: { id: number; assignedNum?: number }[];
};

export type BagAllocation = {
	dropListID: UniqueIdentifier;
	equipmentID: number;
	startTime: string;
	endTime: string;
	rank: number;
	minSlot: number;
	maxSlot: number;
	requirements: number[];
	staffAllocation: Array<{ id: number; assignedNum?: number }>;
	locked: boolean;
	comment?: string;
	description: string;
};

type TemplatesAndStaff = {
	templates: FunctionTemplate[];
	staff: StaffWithLock[];
	staffSchedule: StaffSchedule[];
	setStaff: (val: StaffWithLock[]) => void;
};

// export type SelectedFunction

export const TemplatesAndStaffContext = createContext<TemplatesAndStaff>({
	templates: [],
	staff: [],
	staffSchedule: [],
	setStaff: () => {},
});

const getFunctionTemplates = async () => {
	const res = await restServer.get<FunctionTemplate[]>(
		'views/function-template',
		{
			params: {
				sortBy: 'name',
			},
		}
	);
	return res.data;
};

const getSavedSchedule = async (date: Date) => {
	const formattedDate = format(date, 'yyyy-MM-dd');

	const exists = await restServer.get<FunctionSchedule>(
		`views/function-schedule/exist/${formattedDate}`
	);

	if (!exists.data) {
		return null;
	}

	const res = await restServer.get<FunctionSchedule>(
		`views/function-schedule/${formattedDate}`
	);

	return res.data;
};

const postSchedule = async (schedule: FunctionSchedule) => {
	await restServer.post<FunctionSchedule>(`views/function-schedule`, schedule);
};

const putSchedule = async (schedule: FunctionSchedule) => {
	await restServer.put<FunctionSchedule>(
		`views/function-schedule/${schedule.date}`,
		schedule
	);
};

const getStaff = async () => {
	const res = await restServer.get<StaffWithProfession[]>('views/staff', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

const getStaffSchedule = async (date: Date) => {
	const formattedDate = format(date, 'yyyy-MM-dd');
	const res = await restServer.get<StaffSchedule[]>(
		`views/staff-schedule/${formattedDate}`,
		{
			params: {
				sortBy: 'name',
			},
		}
	);
	return res.data;
};

const getEquipment = async () => {
	const res = await restServer.get<Equipment[]>('equipment', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

const getCertificates = async () => {
	const res = await restServer.get<Certificate[]>('views/certificate', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

// fetch potentially saved schedule and set allocations accordingly
const resetBags = (
	savedSchedule: FunctionSchedule,
	staff: StaffWithLock[],
	setSelectedTemplate: (val: FunctionTemplate) => void,
	setBagAllocations: (val: BagAllocation[]) => void,
	setStaff: (val: StaffWithLock[]) => void
) => {
	// Reset staff placement
	staff.forEach(st => {
		st.placedMorning = 0;
		st.placedAfternoon = 0;
	});

	const template = savedSchedule.jsonData.template;
	const bags = savedSchedule.jsonData.bags.map<BagAllocation>(bag => {
		const bagMorning = bag.startTime === '07:30';
		const bagAfternoon = bag.startTime === '12:30';

		// const possibleDescription =
		// 	template.row
		// 		.find(p => p.equipment?.id === bag.equipment.id)
		// 		?.week.find(
		// 			p =>
		// 				p.startTime === bag.startTime &&
		// 				p.endTime === bag.endTime &&
		// 				p.rank === bag.rank
		// 		)?.description ?? '';

		return {
			dropListID: bag.dropListID,
			equipmentID: bag.equipment.id,
			startTime: bag.startTime,
			endTime: bag.endTime,
			minSlot: bag.minSlot,
			maxSlot: bag.maxSlot,
			rank: bag.rank,
			requirements: bag.requirements.map(req => req.id),
			staffAllocation: bag.staffAllocation.map(sa => {
				const foundStaff = staff.find(st => st.id === sa.id);
				if (foundStaff) {
					foundStaff.placedMorning += bagMorning ? 1 : 0;
					foundStaff.placedAfternoon += bagAfternoon ? 1 : 0;
				}
				return { id: sa.id, assignedNum: sa.numAssignment };
			}),
			locked: bag.locked,
			comment: bag.comment,
			// description: possibleDescription,
			description: bag.description,
		};
	});
	setSelectedTemplate(template);
	setBagAllocations(bags);
	setStaff([...staff]);
};

export function FunctionSchedulePage() {
	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: { distance: 5 },
	});
	const sensors = useSensors(mouseSensor);

	const [selectedDate, setSelectedDate] = useState(new Date());

	const [selectedTemplate, setSelectedTemplate] =
		useState<FunctionTemplate | null>(null);
	const [isSavedTemplate, setIsSavedTemplate] = useState(false);

	const [bagAllocations, setBagAllocations] = useState<BagAllocation[]>([]);

	const [templates, setTemplates] = useState<FunctionTemplate[]>([]);
	useEffect(() => {
		getFunctionTemplates().then(setTemplates);
	}, []);

	const [fetchedStaff, setFetchedStaff] = useState<StaffWithProfession[]>([]);
	useEffect(() => {
		getStaff().then(setFetchedStaff);
	}, []);

	const [staff, setStaff] = useState<StaffWithLock[]>([]);
	const [staffSchedule, setStaffSchedule] = useState<StaffSchedule[]>([]);

	useEffect(() => {
		if (fetchedStaff.length === 0) {
			return;
		}
		getStaffSchedule(selectedDate).then(res => {
			setStaffSchedule(res);

			const staffWithLock = fetchedStaff.map<StaffWithLock>(st => {
				const schedule = res.find(stSch => stSch.staff.id === st.id);
				if (schedule == null) {
					return {
						...st,
						locked: false,
						availableMorning: false,
						availableAfternoon: false,
						placedMorning: 0,
						placedAfternoon: 0,
						unavailable: true,
					};
				}

				// Calculate availability
				const availableMorning = schedule.schedule.some(sch => {
					const morningStart = new Date(sch.startTime).getHours() < 8;
					const lunchEnd = new Date(sch.endTime).getHours() >= 12;
					return sch.isAvailable && morningStart && lunchEnd;
				});

				const availableAfternoon = schedule.schedule.some(sch => {
					const lunchStart = new Date(sch.startTime).getHours() <= 13;
					const eveEnd = new Date(sch.endTime).getHours() >= 16;
					return sch.isAvailable && lunchStart && eveEnd;
				});

				return {
					...st,
					locked: false,
					availableMorning,
					placedMorning: 0,
					availableAfternoon,
					placedAfternoon: 0,
					unavailable: !availableMorning && !availableAfternoon,
				};
			});
			setStaff(staffWithLock);
		});
	}, [selectedDate, fetchedStaff]);

	const [savedSchedule, setSavedSchedule] = useState<FunctionSchedule | null>(
		null
	);
	const [loadingSchedule, setLoadingSchedule] = useState(false);
	const [scheduleLoaded, setScheduleLoaded] = useState(false);
	useEffect(() => {
		setScheduleLoaded(false);
	}, [selectedDate]);
	const [openNumAssignment, setOpenNumAssignment] = useState<boolean>(false);
	const [selectedBag, setSelectedBag] = useState<BagAllocation | undefined>(
		undefined
	);

	const [equipment, setEquipment] = useState<Equipment[]>([]);
	useEffect(() => {
		getEquipment().then(setEquipment);
	}, []);

	const [certificates, setCertificates] = useState<Certificate[]>([]);
	useEffect(() => {
		getCertificates().then(setCertificates);
	}, []);

	const emptyBags = useCallback(
		(template: FunctionTemplate, resetLocked: boolean = false) => {
			// Reset staff placement
			staff.forEach(st => {
				st.placedMorning = 0;
				st.placedAfternoon = 0;
			});
			setStaff([...staff]);

			const bags = template.row.flatMap<BagAllocation>(row =>
				row.week
					.filter(slot => slot.dayIndex === selectedDate.getDay() - 1)
					.map<BagAllocation>(slot => {
						const dropListID = `${row.equipment?.id}-${slot.startTime}-${slot.endTime}-${slot.rank}`;
						const bagAlloc = bagAllocations.find(
							p => p.dropListID === dropListID
						);
						return {
							dropListID: dropListID,
							equipmentID: row.equipment?.id ?? -1,
							startTime: slot.startTime,
							endTime: slot.endTime,
							rank: slot.rank,
							minSlot: slot.minSlot,
							maxSlot: slot.maxSlot,
							requirements: slot.certificates.map(cert =>
								cert ? cert.id : -1
							),
							staffAllocation: [],
							description: slot.description,
							locked:
								resetLocked === false
									? false
									: bagAlloc === undefined
									? false
									: bagAlloc.locked,
						};
					})
			);

			setBagAllocations(bags);
			if (savedSchedule == null) {
				setAllocationsChanged(false);
			}
		},
		// eslint-disable-next-line
		[selectedDate, staff, savedSchedule]
	);

	// Fetch saved schedule
	useEffect(() => {
		if (scheduleLoaded || staff.length === 0) {
			return;
		}
		setLoadingSchedule(true);
		getSavedSchedule(selectedDate).then(res => {
			setSavedSchedule(res);
			if (res) {
				setIsSavedTemplate(true);
				resetBags(res, staff, setSelectedTemplate, setBagAllocations, setStaff);
			} else if (selectedTemplate) {
				setIsSavedTemplate(false);
				emptyBags(selectedTemplate);
			}
			setLoadingSchedule(false);
			setScheduleLoaded(true);
		});
	}, [selectedDate, staff, scheduleLoaded, emptyBags, selectedTemplate]);

	const [allocationsChanged, setAllocationsChanged] = useState(false);

	const [draggingStaff, setDraggingStaff] = useState<number | null>(null);

	if (
		staff.length === 0 ||
		equipment.length === 0 ||
		certificates.length === 0
	) {
		return <></>;
	}

	return (
		<DndContext
			sensors={sensors}
			onDragStart={event => {
				const data = event.active.data.current as DraggingStaffData;
				if (!data) {
					return;
				}
				setDraggingStaff(data.staffID);
			}}
			onDragEnd={event => {
				setDraggingStaff(null);
				const dragData = event.active.data.current as DraggingStaffData;
				const over = event.over;
				if (!dragData) {
					return;
				}
				const staffID = dragData.staffID;
				const alloc = dragData.allocation;
				const dragStaff = staff.find(st => st.id === staffID);

				if (!dragStaff) {
					return;
				}

				if (over != null) {
					const bag = bagAllocations.find(ba => ba.dropListID === over.id);
					if (!bag) {
						return;
					}

					if (dragData.allocation != null) {
						const prevBag = bagAllocations.find(
							ba => ba.dropListID === dragData.allocation
						);
						if (!prevBag) {
							return;
						}
						prevBag.staffAllocation.splice(
							prevBag.staffAllocation.findIndex(sa => sa.id === staffID),
							1
						);
						const prevBagMorning = prevBag.startTime === '07:30';
						const prevBagAfternoon = prevBag.startTime === '12:30';
						dragStaff.placedMorning -= prevBagMorning ? 1 : 0;
						dragStaff.placedAfternoon -= prevBagAfternoon ? 1 : 0;
					}
					if (!bag.staffAllocation.some(sa => sa.id === staffID)) {
						bag.staffAllocation.push({ id: staffID });
						const bagMorning = bag.startTime === '07:30';
						const bagAfternoon = bag.startTime === '12:30';
						dragStaff.placedMorning += bagMorning ? 1 : 0;
						dragStaff.placedAfternoon += bagAfternoon ? 1 : 0;
					}
				} else {
					const bag = bagAllocations.find(ba => ba.dropListID === alloc);
					if (!bag) {
						return;
					}
					const bagMorning = bag.startTime === '07:30';
					const bagAfternoon = bag.startTime === '12:30';
					dragStaff.placedMorning -= bagMorning ? 1 : 0;
					dragStaff.placedAfternoon -= bagAfternoon ? 1 : 0;
					bag.staffAllocation.splice(
						bag.staffAllocation.findIndex(sa => sa.id === staffID),
						1
					);
				}
				setStaff([...staff]);
				setBagAllocations([...bagAllocations]);
				setAllocationsChanged(true);
			}}
			onDragCancel={() => setDraggingStaff(null)}
		>
			<TemplatesAndStaffContext.Provider
				value={{ templates, staff, setStaff, staffSchedule }}
			>
				<StaffNumAssignmentDialog
					open={openNumAssignment}
					setOpen={() => setOpenNumAssignment(false)}
					bag={selectedBag}
					onSave={(nums, comment) => {
						if (!selectedBag) {
							return;
						}
						selectedBag.comment = comment;
						nums.forEach(num => {
							const found = selectedBag.staffAllocation.find(
								st => st.id === num.staffID
							);
							if (found) {
								found.assignedNum = num.assignedNum;
							}
						});
						setBagAllocations([...bagAllocations]);
						setAllocationsChanged(true);
					}}
				/>
				<Typography variant='h3'>Funktionsschema</Typography>

				<Paper
					sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1, p: 1 }}
				>
					<DesktopDatePicker
						value={selectedDate}
						onChange={val => {
							setSelectedDate(val ?? selectedDate);
						}}
						label={'Datum'}
					/>
					{selectedTemplate && (
						<Box width={300}>
							<Autocomplete
								fullWidth
								renderInput={params => <TextField {...params} />}
								options={(() => {
									const out = templates.map(t => ({ ...t, saved: false }));
									if (savedSchedule) {
										return [
											{
												...savedSchedule.jsonData.template,
												saved: true,
											},
										].concat(out);
									}
									return out;
								})()}
								getOptionLabel={opt =>
									opt.name + (opt.saved ? ' (Sparad)' : '')
								}
								getOptionKey={opt =>
									`${opt.id}-${opt.saved ? 'saved' : 'unsaved'}`
								}
								isOptionEqualToValue={(a, b) =>
									a.id === b.id && a.saved === b.saved
								}
								value={{ ...selectedTemplate, saved: isSavedTemplate }}
								onChange={(_, val) => {
									setSelectedTemplate(val);

									if (!val) {
										setIsSavedTemplate(false);
										return;
									}

									setIsSavedTemplate(val?.saved);

									if (val.saved && savedSchedule) {
										resetBags(
											savedSchedule,
											staff,
											setSelectedTemplate,
											setBagAllocations,
											setStaff
										);
									} else {
										emptyBags(val);
									}
								}}
							/>
						</Box>
					)}
					{selectedTemplate && (
						<SaveButton
							date={selectedDate}
							selectedTemplate={selectedTemplate}
							bagAllocations={bagAllocations}
							allocationsChanged={allocationsChanged}
							setAllocationsChanged={setAllocationsChanged}
							equipment={equipment}
							certificates={certificates}
							savedSchedule={savedSchedule}
						/>
					)}
					{selectedTemplate && (
						<Tooltip title={'Återställ'}>
							<IconButton
								onClick={() => {
									if (savedSchedule != null) {
										resetBags(
											savedSchedule,
											staff,
											setSelectedTemplate,
											setBagAllocations,
											setStaff
										);
									} else {
										emptyBags(selectedTemplate);
									}
									setAllocationsChanged(false);
								}}
							>
								<Replay />
							</IconButton>
						</Tooltip>
					)}
				</Paper>

				<Paper sx={{ display: 'flex', gap: 4, p: 1 }}>
					<Box width={'15vw'}>
						{!selectedTemplate ? (
							<TemplateList
								setSelectedTemplate={val => {
									setSelectedTemplate(val);
									if (savedSchedule != null) {
										if (savedSchedule.jsonData.template.id === val.id) {
											resetBags(
												savedSchedule,
												staff,
												setSelectedTemplate,
												setBagAllocations,
												setStaff
											);
											return;
										} else {
										}
									}
									setAllocationsChanged(true);
									emptyBags(val);
								}}
							/>
						) : (
							<StaffList draggingStaff={draggingStaff} />
						)}
					</Box>

					{loadingSchedule ? (
						<LinearProgress />
					) : (
						<Box
							hidden={!selectedTemplate}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								minHeight: '68vh',
								width: '100%',
							}}
						>
							{selectedTemplate && (
								<Timeslots
									date={selectedDate}
									selectedTemplate={selectedTemplate}
									isDragging={Boolean(draggingStaff)}
									bagAllocations={bagAllocations}
									setBagAllocations={val => {
										setBagAllocations(val);
										setAllocationsChanged(true);
									}}
									setSelectedBag={setSelectedBag}
									setOpen={() => setOpenNumAssignment(true)}
								/>
							)}
							<Box display={'flex'} gap={1} mt={'auto'} alignSelf={'center'}>
								<OptimizeButton
									selectedTemplate={selectedTemplate}
									bagAllocations={bagAllocations}
									setBagAllocations={val => {
										setBagAllocations(val);
										setAllocationsChanged(true);
									}}
								/>
								{selectedTemplate && savedSchedule && (
									<Tooltip title={'Ta bort all allokerad personal'}>
										<IconButton
											onClick={() => {
												emptyBags(selectedTemplate, true);
												setAllocationsChanged(true);
											}}
										>
											<Clear />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						</Box>
					)}
				</Paper>
			</TemplatesAndStaffContext.Provider>
		</DndContext>
	);
}

type TemplateListProps = {
	setSelectedTemplate: (val: FunctionTemplate) => void;
};

function TemplateList(props: TemplateListProps) {
	const { templates } = useContext(TemplatesAndStaffContext);
	const { setSelectedTemplate } = props;

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					border: 1,
					borderColor: 'divider',
					px: 1,
					py: 0.5,
				}}
			>
				<Typography variant='subtitle1'>{`Funktionsmallar ${templates.length} st`}</Typography>
			</Box>
			<TList
				items={templates}
				selected={undefined}
				setSelected={setSelectedTemplate}
			/>
		</>
	);
}

type StaffListProps = {
	draggingStaff: number | null;
};

function StaffList(props: StaffListProps) {
	const { draggingStaff } = props;
	const { staff } = useContext(TemplatesAndStaffContext);

	const draggingStaffObject = staff.find(st => st.id === draggingStaff);

	return (
		<>
			<Box
				sx={{
					position: 'sticky',
					top: '10%',
				}}
			>
				<Typography
					sx={{
						border: 1,
						borderColor: 'divider',
						px: 1,
						py: 0.5,
					}}
					variant='subtitle1'
				>{`Personal ${staff.length} st`}</Typography>
				<Box
					border={'solid 1px'}
					borderColor={'divider'}
					sx={{
						position: 'sticky',
						top: '10%',
					}}
				>
					<List
						sx={{
							minHeight: 600,
							height: '60vh',
							overflowY: 'scroll',
							p: 0,
							'.MuiListItem-root': {
								p: 1,
								':hover': {
									background: 'rgba(0, 0, 0, 0.04)',
								},
							},
						}}
					>
						{staff
							.sort((_, b) => -Number(b.unavailable))
							.filter(
								st =>
									st.unavailable ||
									(st.availableMorning && st.placedMorning === 0) ||
									(st.availableAfternoon && st.placedAfternoon === 0)
							)
							.map(st => {
								return st.id !== draggingStaff ? (
									<DraggableStaff
										staff={st}
										inactive={st.locked || st.unavailable}
										key={`entry-${st.id}`}
									/>
								) : (
									<DraggableStaff
										staff={st}
										inactive={true}
										key={`drag-entry-${st.id}`}
									/>
								);
							})}
					</List>
				</Box>
			</Box>
			<DragOverlay>
				{draggingStaffObject && <DraggableStaff staff={draggingStaffObject} />}
			</DragOverlay>
		</>
	);
}

type TimeslotsProps = {
	date: Date;
	selectedTemplate: FunctionTemplate;
	isDragging: boolean;
	bagAllocations: BagAllocation[];
	setBagAllocations: (val: BagAllocation[]) => void;
	setSelectedBag: (val: BagAllocation) => void;
	setOpen: () => void;
};

function Timeslots(props: TimeslotsProps) {
	const {
		date,
		selectedTemplate: template,
		isDragging,
		bagAllocations,
		setBagAllocations,
	} = props;

	const { staff } = useContext(TemplatesAndStaffContext);

	const dayOfWeek = date.getDay() - 1;

	const slots = template.row.map(row => {
		return {
			equipment: row.equipment,
			rank: row.rank,
			times: row.week.filter(day => day.dayIndex === dayOfWeek),
		};
	});

	const timeSort = (a: TemplateWeekDay, b: TemplateWeekDay): number => {
		const startTimeDif = parseInt(a.startTime) - parseInt(b.startTime);
		if (startTimeDif !== 0) {
			return startTimeDif;
		}
		return a.rank - b.rank;
	};

	return (
		<Box
			display={'grid'}
			gridTemplateColumns={'repeat(4, 1fr)'}
			columnGap={1}
			rowGap={1}
			p={1}
		>
			{slots
				.filter(({ times }) => times.length > 0)
				.sort((a, b) => a.rank - b.rank)
				.map(slot => {
					return (
						<Box
							key={`${slot.equipment?.id}-${date.getDay()}` ?? 0}
							display={'flex'}
							flexDirection={'column'}
							gap={'1px'}
						>
							<Typography
								sx={{
									p: 1,
									fontWeight: 'bold',
									outline: '1px solid black',
								}}
							>
								{slot.equipment?.name}
							</Typography>
							{slot.times.sort(timeSort).map(s => {
								const equipID = slot.equipment?.id ?? -1;
								const bag = bagAllocations.find(
									b =>
										b.dropListID ===
										`${equipID}-${s.startTime}-${s.endTime}-${s.rank}`
								);
								if (!bag) {
									return (
										<React.Fragment
											key={
												`${slot.equipment?.id}-${s.startTime}-${s.rank}` ?? 0
											}
										></React.Fragment>
									);
								}
								const lengthError = bag.staffAllocation.length > bag.maxSlot;
								const certError = bag.staffAllocation.some(sa => {
									const st = staff.find(st => st.id === sa.id);
									return !bag.requirements.every(req =>
										st?.certificate.find(c => c.id === req)
									);
								});
								const allocationCountError = bag.staffAllocation.some(sa => {
									const foundStaff = staff.find(st => st.id === sa.id);
									if (!foundStaff) {
										return false;
									}
									return (
										foundStaff.placedMorning > 1 ||
										foundStaff.placedAfternoon > 1
									);
								});
								const bagError =
									lengthError || certError || allocationCountError;

								const unfilledBag = bag.staffAllocation.length < bag.maxSlot;

								const boxShadow =
									bagError || unfilledBag
										? `0 0 5px ${bagError ? 'red' : 'orange'}`
										: undefined;

								const outline = `1px solid ${
									bagError
										? 'red'
										: unfilledBag
										? bag.locked
											? 'black'
											: 'orange'
										: 'black'
								}`;

								return (
									<Box
										p={1}
										flexBasis={200}
										key={`${slot.equipment?.id}-${s.startTime}-${s.rank}` ?? 0}
										sx={{
											background: '#FFF',
											outline: outline,
											boxShadow: boxShadow,
											zIndex: bagError ? 1 : 0,
										}}
										display={'flex'}
										flexDirection={'column'}
									>
										<Box
											display={'flex'}
											alignItems={'center'}
											sx={{ background: bag.locked ? 'gray' : undefined }}
										>
											<Tooltip title={'Stäng av utrustning vid denna tid'}>
												<IconButton
													onClick={() => {
														bag.locked = !bag.locked;
														setBagAllocations([...bagAllocations]);
													}}
												>
													{bag.locked ? <Lock /> : <LockOpen />}
												</IconButton>
											</Tooltip>
											<Typography>
												{s.startTime}-{s.endTime} {s.description}
											</Typography>
											{bag.comment && (
												<Tooltip
													sx={{ ml: 1 }}
													title={bag.comment}
													slotProps={{ tooltip: { sx: { fontSize: 16 } } }}
												>
													<ChatOutlined color='primary' />
												</Tooltip>
											)}
											<Tooltip title='Personal numrering'>
												<IconButton
													onClick={() => {
														if (bag) {
															props.setSelectedBag(bag);
															props.setOpen();
														}
													}}
												>
													<Add />
												</IconButton>
											</Tooltip>
											<Typography sx={{ flexShrink: 0, ml: 'auto' }}>
												{s.minSlot}-{s.maxSlot}
											</Typography>
										</Box>
										<Divider />
										<DroppableList
											equipmentID={equipID}
											startTime={s.startTime}
											endTime={s.endTime}
											rank={s.rank}
											isDragging={isDragging}
											bagAllocations={bagAllocations}
											setBagAllocations={setBagAllocations}
										/>
									</Box>
								);
							})}
						</Box>
					);
				})}
		</Box>
	);
}

type DroppableListProps = {
	equipmentID: number;
	startTime: string;
	endTime: string;
	rank: number;
	isDragging: boolean;
	bagAllocations: BagAllocation[];
	setBagAllocations: (val: BagAllocation[]) => void;
};

function DroppableList(props: DroppableListProps) {
	const {
		equipmentID,
		startTime,
		endTime,
		rank,
		bagAllocations,
		setBagAllocations,
	} = props;
	const { staff } = useContext(TemplatesAndStaffContext);

	const dropListID = useMemo(
		() => `${equipmentID}-${startTime}-${endTime}-${rank}`,
		[equipmentID, startTime, endTime, rank]
	);

	const { setNodeRef } = useDroppable({
		id: dropListID,
		data: { equipmentID, startTime, endTime } as DropListData,
	});

	const [bagAlloc, allocatedStaff] = useMemo(() => {
		const bagAlloc = bagAllocations.find(ba => ba.dropListID === dropListID);
		const allocatedStaff = bagAlloc?.staffAllocation.map(sa =>
			staff.find(st => st.id === sa.id)
		) as StaffWithLock[];

		return [bagAlloc, allocatedStaff];
	}, [bagAllocations, dropListID, staff]);

	if (!bagAlloc) {
		return <></>;
	}

	return (
		<List
			ref={setNodeRef}
			sx={{
				background: bagAlloc?.locked ? 'lightgray' : undefined,
				flexGrow: 1,
				'.MuiListItem-root': {
					p: 1,
					':hover': {
						background: 'rgba(0, 0, 0, 0.04)',
					},
				},
			}}
		>
			{allocatedStaff?.map(st => {
				return (
					<DraggableStaff
						staff={st}
						hasRemove={true}
						onRemove={() => {
							if (!bagAlloc) {
								return;
							}
							const stAlloc = bagAlloc.staffAllocation;
							stAlloc.splice(
								stAlloc.findIndex(sa => sa.id === st.id),
								1
							);
							setBagAllocations([...bagAllocations]);
							const foundStaff = staff.find(s => s.id === st.id);
							if (foundStaff != null) {
								const bagMorning = bagAlloc.startTime === '07:30';
								const bagAfternoon = bagAlloc.startTime === '12:30';
								if (bagMorning) {
									foundStaff.placedMorning -= 1;
								}
								if (bagAfternoon) {
									foundStaff.placedAfternoon -= 1;
								}
							}
						}}
						inList={dropListID}
						dropListData={{
							equipmentID,
							startTime,
							endTime,
							allocatedStaff: bagAlloc?.staffAllocation,
						}}
						showLocked={true}
						key={st.id}
					/>
				);
			})}
		</List>
	);
}

type DraggableStaffProps = {
	staff: StaffWithLock;
	hasRemove?: boolean;
	onRemove?: () => void;
	inactive?: boolean;
	inList?: UniqueIdentifier;
	dropListData?: DropListData;
	showLocked?: boolean;
};

function DraggableStaff(props: DraggableStaffProps) {
	const {
		staff,
		hasRemove,
		onRemove,
		inactive,
		inList,
		dropListData,
		showLocked,
	} = props;
	const { staff: staffList, setStaff } = useContext(TemplatesAndStaffContext);

	const dragID = !Boolean(inList)
		? `staff-${staff.id}`
		: `staff-${staff.id}-${inList}`;

	const { attributes, listeners, setNodeRef } = useDraggable({
		id: dragID,
		data: { staffID: staff.id, allocation: inList } as DraggingStaffData,
		disabled: inactive,
	});

	const showFM = dropListData
		? dropListData.startTime === '07:30'
			? true
			: false
		: staff.availableMorning && staff.placedMorning === 0;

	const showEM = dropListData
		? dropListData.startTime === '12:30'
			? true
			: false
		: staff.availableAfternoon && staff.placedAfternoon === 0;

	const allocStaff = dropListData?.allocatedStaff.find(
		st => st.id === staff.id
	);

	return (
		<ListItem
			key={staff.id}
			sx={{
				border: 1,
				borderColor: 'divider',
				backgroundColor: '#fff',
				py: 0,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 0.5,
				color: inactive ? 'gray' : undefined,
			}}
			{...listeners}
			{...attributes}
			ref={setNodeRef}
		>
			<Typography sx={{ mr: 'auto' }}>
				{`${staff.firstName} ${staff.lastName}`}
			</Typography>
			{allocStaff?.assignedNum && (
				<Chip label={`${allocStaff.assignedNum}`} color={'info'} />
			)}
			{showFM && <Chip label='FM' color='success' />}
			{showEM && <Chip label='EM' color='warning' />}
			{staff.unavailable && <Chip label={'Otillgänglig'} />}
			<Box>
				{!showLocked && (
					<Tooltip title={staff.locked ? 'Lås upp personal' : 'Lås personal'}>
						<IconButton
							onClick={() => {
								if (showLocked) {
									return;
								}
								const copy = [...staffList];
								const st = copy.find(st => st.id === staff.id);
								if (st) {
									st.locked = !st.locked;
								}
								setStaff(copy);
							}}
						>
							{staff.locked || showLocked ? <Lock /> : <LockOpen />}
						</IconButton>
					</Tooltip>
				)}
				{hasRemove && (
					<Tooltip title={'Ta bort från funktion'}>
						<IconButton onClick={() => onRemove?.()}>
							<Clear />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		</ListItem>
	);
}

type OptimizeButtonProps = {
	selectedTemplate: FunctionTemplate | null;
	bagAllocations: BagAllocation[];
	setBagAllocations: (val: BagAllocation[]) => void;
};

function OptimizeButton(props: OptimizeButtonProps) {
	const { selectedTemplate, bagAllocations, setBagAllocations } = props;
	const { staff, setStaff } = useContext(TemplatesAndStaffContext);

	const onClick = () => {
		restServer
			.post<MKPQuery>('v1/model/mkp', {
				bags: bagAllocations
					.map((bag, idx) => ({ ...bag, bagIndex: idx }))
					.filter(bag => !bag.locked)
					.map<MKPBag>(bag => ({
						id: bag.bagIndex,
						minSlot: bag.minSlot,
						maxSlot: bag.maxSlot,
						timeSlot: bag.startTime === '07:30' ? 'MORNING' : 'AFTERNOON',
						requirements: bag.requirements,
						staffAllocation: bag.staffAllocation
							.map(sa => staff.find(st => st.id === sa.id))
							.filter((item): item is StaffWithLock => Boolean(item))
							.map(st => ({
								id: st.id,
								timeSlot:
									st.availableMorning && st.availableAfternoon
										? 'FULLDAY'
										: st.availableMorning
										? 'MORNING'
										: st.availableAfternoon
										? 'MORNING'
										: 'UNAVAILABLE', // Should never happen
								certificates: st.certificate.map(cert => cert.id),
							})),
					})),
				staff: staff
					.filter(
						st => !st.locked && (st.availableMorning || st.availableAfternoon)
					)
					.map(st => ({
						id: st.id,
						timeSlot:
							st.availableMorning && st.availableAfternoon
								? 'FULLDAY'
								: st.availableMorning
								? 'MORNING'
								: st.availableAfternoon
								? 'MORNING'
								: 'UNAVAILABLE', // Should never happen
						certificates: st.certificate
							.filter(cert => !cert.isTraining)
							.map(cert => cert.id),
					})),
			})
			.then(res => {
				staff.forEach(st => {
					st.placedMorning = 0;
					st.placedAfternoon = 0;
				});
				const bags = res.data.bags;
				bags.forEach(bag => {
					bagAllocations[bag.id].staffAllocation = bag.staffAllocation.map(
						sa => {
							const foundStaff = staff.find(st => st.id === sa.id);
							if (foundStaff) {
								foundStaff.placedMorning += bag.timeSlot === 'MORNING' ? 1 : 0;
								foundStaff.placedAfternoon +=
									bag.timeSlot === 'AFTERNOON' ? 1 : 0;
							}
							return { id: sa.id };
						}
					);
				});
				setStaff([...staff]);
				setBagAllocations([...bagAllocations]);
			});
	};

	return (
		<Button
			disabled={!Boolean(selectedTemplate)}
			variant={'contained'}
			color={'success'}
			size='large'
			sx={
				{
					// width: '16em',
					// height: 40,
					// fontSize: 20,
				}
			}
			onClick={onClick}
		>
			MAGIC
		</Button>
	);
}

type SaveButtonProps = {
	selectedTemplate: FunctionTemplate;
	date: Date;
	bagAllocations: BagAllocation[];
	allocationsChanged: boolean;
	setAllocationsChanged: (val: boolean) => void;
	equipment: Equipment[];
	certificates: Certificate[];
	savedSchedule: FunctionSchedule | null;
};

function SaveButton(props: SaveButtonProps) {
	const { staff } = useContext(TemplatesAndStaffContext);

	const {
		selectedTemplate,
		date,
		bagAllocations,
		allocationsChanged,
		setAllocationsChanged,
		equipment,
		certificates,
		savedSchedule,
	} = props;

	const { setShow } = useContext(SnackbarContext);

	const onClick = () => {
		const saveBagAllocs = bagAllocations
			.map<FunctionScheduleBag | null>(ba => {
				const equip = equipment.find(e => e.id === ba.equipmentID);
				if (!equip) {
					return null;
				}

				const certs = ba.requirements
					.map(req => certificates.find(c => c.id === req))
					.filter((cert): cert is Certificate => Boolean(cert));

				const allocStaff = ba.staffAllocation
					.map(sa => {
						const found = staff.find(st => st.id === sa.id);
						if (found) {
							return { ...found, numAssignment: sa.assignedNum };
						}
						return found;
					})
					.filter(
						(st): st is StaffWithLock & { numAssignment: number | undefined } =>
							Boolean(st)
					);

				return {
					dropListID: ba.dropListID,
					equipment: equip,
					startTime: ba.startTime,
					endTime: ba.endTime,
					rank: ba.rank,
					minSlot: ba.minSlot,
					maxSlot: ba.maxSlot,
					requirements: certs,
					staffAllocation: allocStaff,
					locked: ba.locked,
					comment: ba.comment,
					description: ba.description,
				};
			})
			.filter((item): item is FunctionScheduleBag => Boolean(item));

		const saveFunc = (
			restFunc: (schedule: FunctionSchedule) => Promise<unknown>,
			message: string
		) => {
			const dateString = format(date, 'yyyy-MM-dd');
			restFunc({
				date: dateString,
				jsonData: {
					template: selectedTemplate,
					bags: saveBagAllocs,
				},
				version: '',
			})
				.then(() => {
					setShow({
						message: `Funktionsmall ${dateString} ${message}.`,
						messageType: 'Success',
					});
					setAllocationsChanged(false);
				})
				.catch((err: AxiosError) => {
					setShow({
						message: `${err.response?.statusText} ${err.response?.status}`,
						messageType: 'Error',
					});
				});
		};

		if (savedSchedule != null) {
			saveFunc(putSchedule, 'uppdaterad');
			// putSchedule({
			// 	date: dateString,
			// 	jsonData: {
			// 		template: selectedTemplate,
			// 		bags: saveBagAllocs,
			// 	},
			// 	version: '',
			// })
			// 	.then(() => {
			// 		setShow({
			// 			message: `Funktionsmall ${dateString} uppdaterad.`,
			// 			messageType: 'Success',
			// 		});
			// 		setAllocationsChanged(false);
			// 	})
			// 	.catch((err: AxiosError) => {
			// 		setShow({
			// 			message: `${err.response?.statusText} ${err.response?.status}`,
			// 			messageType: 'Error',
			// 		});
			// 	});
		} else {
			saveFunc(postSchedule, 'sparad');
			// postSchedule({
			// 	date: dateString,
			// 	jsonData: {
			// 		template: selectedTemplate,
			// 		bags: saveBagAllocs,
			// 	},
			// 	version: '',
			// })
			// 	.then(() => {
			// 		setShow({
			// 			message: `Funktionsmall ${dateString} sparad.`,
			// 			messageType: 'Success',
			// 		});
			// 		setAllocationsChanged(false);
			// 	})
			// 	.catch((err: AxiosError) =>
			// 		setShow({
			// 			message: `${err.response?.statusText} ${err.response?.status}`,
			// 			messageType: 'Error',
			// 		})
			// 	);
		}
	};

	return (
		<Button
			startIcon={<Save />}
			variant={'contained'}
			color={'success'}
			disabled={!allocationsChanged}
			onClick={onClick}
		>
			Spara
		</Button>
	);
}
