import {
	Typography,
	Paper,
	Box,
	Button,
	TextField,
	Divider,
	IconButton,
	CircularProgress,
	LinearProgress,
} from '@mui/material';
import { restServer } from '../../services/AxiosConfiguration';
import { StaffWithProfession, StaffSchedule } from '../rest-models/rest-model';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { addDays, format } from 'date-fns';
import { Add, Clear, Save } from '@mui/icons-material';
import { setTime } from '../common/DateUtility';
import { CheckboxButton } from '../common/CheckboxButton';
import React from 'react';

type ScheduleSlot = {
	startTime: string;
	endTime: string;
	available: boolean;
	absentReason: string;
};

type DaySchedule = {
	day: number;
	dayName: string;
	dayDate: Date;
	slots: ScheduleSlot[];
};

type StaffWithSchedule = {
	staff: StaffWithProfession;
	days: DaySchedule[];
};

const DAY_NAMES = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'] as const;

const transformToStaffWithSchedule = (
	staff: StaffSchedule,
	monday: Date
): StaffWithSchedule => {
	const days = new Array<DaySchedule>();
	for (let i = 0; i < 5; i++) {
		const dayDate = addDays(monday, i);
		const day = format(dayDate, 'yyyy-MM-dd');
		days.push({
			day: i,
			dayName: DAY_NAMES[i],
			dayDate: dayDate,
			slots: staff.schedule
				.filter(sch => {
					const schDay = format(new Date(sch.startTime), 'yyyy-MM-dd');
					return schDay === day;
				})
				.map(sch => ({
					startTime: format(new Date(sch.startTime), 'HH:mm'),
					endTime: format(new Date(sch.endTime), 'HH:mm'),
					available: sch.isAvailable,
					absentReason: sch.description === null ? '' : sch.description,
				})),
		});
	}

	return {
		staff: staff.staff,
		days: days,
	};
};

const transformToStaffSchedule = (staff: StaffWithSchedule): StaffSchedule => {
	return {
		staff: staff.staff,
		schedule: staff.days.flatMap(day =>
			day.slots.map(slot => ({
				startTime: format(
					setTime(day.dayDate, slot.startTime),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				endTime: format(
					setTime(day.dayDate, slot.endTime),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				isAvailable: slot.available,
				description: slot.absentReason,
			}))
		),
	};
};

const StaffContext = createContext<{
	staff: StaffWithSchedule[];
	setStaff: (val: StaffWithSchedule[]) => void;
	staffChanged: boolean;
	setStaffChanged: (val: boolean) => void;
}>({
	staff: [],
	setStaff: () => {},
	staffChanged: false,
	setStaffChanged: () => {},
});

const SettingsContext = createContext<{
	date: Date;
	setDate: (val: Date) => void;
	searchTerm: string;
	setSearchTerm: (val: string) => void;
}>({
	date: new Date(),
	setDate: () => {},
	searchTerm: '',
	setSearchTerm: () => {},
});

const getSchedule = async (startDate: Date, endDate: Date) => {
	return await restServer
		.get<StaffSchedule[]>(`views/staff-schedule`, {
			params: {
				startDate: format(startDate, 'yyyy-MM-dd'),
				endDate: format(endDate, 'yyyy-MM-dd'),
			},
		})
		.then(res => res.data);
};

const putSchedule = async (
	schedule: StaffSchedule[],
	startDate: Date,
	endDate: Date
) => {
	return await restServer
		.put<StaffSchedule[]>(`views/staff-schedule`, schedule, {
			params: {
				startDate: format(startDate, 'yyyy-MM-dd'),
				endDate: format(endDate, 'yyyy-MM-dd'),
			},
		})
		.then(res => res.data);
};

export function StaffSchedulePage() {
	const [date, setDate] = useState<Date>(new Date());
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [staffChanged, setStaffChanged] = useState(false);
	const [loadingStaff, setLoadingStaff] = useState(false);

	const [staff, setStaff] = useState<StaffWithSchedule[]>([]);
	useEffect(() => {
		const day = date.getDay();
		const monday = addDays(date, 1 - day);
		const friday = addDays(monday, 4);
		setLoadingStaff(true);
		getSchedule(monday, friday).then(res => {
			const staffWithSchedule = res.map(stsc =>
				transformToStaffWithSchedule(stsc, monday)
			);
			setStaff(staffWithSchedule);
			setLoadingStaff(false);
		});
	}, [date]);

	return (
		<StaffContext.Provider
			value={{
				staff,
				setStaff: val => {
					setStaff(val);
					setStaffChanged(true);
				},
				staffChanged,
				setStaffChanged,
			}}
		>
			<SettingsContext.Provider
				value={{ date, setDate, searchTerm, setSearchTerm }}
			>
				<MainPage loadingStaff={loadingStaff} />
			</SettingsContext.Provider>
		</StaffContext.Provider>
	);
}

function MainPage(props: { loadingStaff: boolean }) {
	const { loadingStaff } = props;
	return (
		<>
			<Typography variant='h3'>Personalschema</Typography>
			<Paper
				sx={{
					p: 1,
					display: 'grid',
					gridTemplateColumns: 'repeat(6, 1fr)',
					columnGap: 1,
					rowGap: 1,
				}}
			>
				<Filter />
				{!loadingStaff ? <Schedule /> : <LinearProgress />}
			</Paper>
		</>
	);
}

function Filter() {
	const { staff, setStaff, staffChanged, setStaffChanged } =
		useContext(StaffContext);
	const { date, setDate } = useContext(SettingsContext);

	const [loading, setLoading] = useState(false);

	const monday = useMemo(() => {
		const day = date.getDay();
		return addDays(date, 1 - day);
	}, [date]);

	const onSave = () => {
		const xformedSchedules = staff.map(transformToStaffSchedule);
		const friday = addDays(monday, 4);
		setLoading(true);
		putSchedule(xformedSchedules, monday, friday).then(res => {
			const staffWithSchedule = res.map(stsc =>
				transformToStaffWithSchedule(stsc, monday)
			);
			setStaff(staffWithSchedule);
			setStaffChanged(false);
			setLoading(false);
		});
	};

	return (
		<Box sx={{ gridColumn: 'span 6', display: 'flex', gap: 1 }}>
			{/* <TextField label={'Sök personal'} /> */}
			<DesktopDatePicker
				label={'Datum'}
				value={date}
				onChange={val => setDate(val ?? date)}
			/>
			<Button
				disabled={!staffChanged}
				variant='contained'
				startIcon={<Save />}
				color={'success'}
				onClick={onSave}
			>
				Spara
			</Button>
			{loading && <CircularProgress size={20} sx={{ alignSelf: 'center' }} />}
		</Box>
	);
}

function Schedule() {
	const { staff, setStaff } = useContext(StaffContext);
	const { date } = useContext(SettingsContext);

	const monday = useMemo(() => {
		const day = date.getDay();
		return addDays(date, 1 - day);
	}, [date]);

	return (
		<>
			<Typography fontWeight={'bold'}>Personal</Typography>
			<Typography fontWeight={'bold'}>
				Måndag {format(monday, 'dd/MM')}
			</Typography>
			<Typography fontWeight={'bold'}>
				Tisdag {format(addDays(monday, 1), 'dd/MM')}
			</Typography>
			<Typography fontWeight={'bold'}>
				Onsdag {format(addDays(monday, 2), 'dd/MM')}
			</Typography>
			<Typography fontWeight={'bold'}>
				Torsdag {format(addDays(monday, 3), 'dd/MM')}
			</Typography>
			<Typography fontWeight={'bold'}>
				Fredag {format(addDays(monday, 4), 'dd/MM')}
			</Typography>
			<Divider sx={{ gridColumn: 'span 6' }} />
			{staff.map(st => (
				<React.Fragment key={st.staff.id}>
					<Row
						staff={st}
						setStaff={val => {
							st = { ...val };
							setStaff([...staff]);
						}}
					/>
					<Divider sx={{ gridColumn: 'span 6' }} />
				</React.Fragment>
			))}
		</>
	);
}

function Row(props: {
	staff: StaffWithSchedule;
	setStaff: (val: StaffWithSchedule) => void;
}) {
	const { staff, setStaff } = props;
	return (
		<Box
			p={1}
			display={'grid'}
			gridColumn={'span 6'}
			gap={1}
			gridTemplateColumns={'repeat(6, 1fr)'}
		>
			<DisplayStaff staff={staff.staff} />
			{staff.days.map((day, idx) => (
				<Day
					day={day}
					setDay={val => {
						staff.days[idx] = val;
						setStaff({ ...staff });
					}}
					key={day.day}
				/>
			))}
		</Box>
	);
}

function DisplayStaff(props: { staff: StaffWithProfession }) {
	const { staff } = props;
	return (
		<Box display={'flex'} alignItems={'center'}>
			<Typography>
				{staff.firstName} {staff.lastName}
			</Typography>
		</Box>
	);
}

function Day(props: { day: DaySchedule; setDay: (val: DaySchedule) => void }) {
	const { day, setDay } = props;

	const slots = day.slots.map((slot, idx) => (
		<Slot day={day} slot={slot} slotIdx={idx} setDay={setDay} key={idx} />
	));

	return (
		<Box display={'flex'} flexDirection={'column'} gap={1}>
			{slots}
			<AddNewSlotButton day={day} setDay={setDay} />
		</Box>
	);
}

function Slot(props: {
	day: DaySchedule;
	slot: ScheduleSlot;
	slotIdx: number;
	setDay: (val: DaySchedule) => void;
}) {
	const { setStaffChanged } = useContext(StaffContext);
	const { day, slot, slotIdx, setDay } = props;

	const [absentReason, setAbsentReason] = useState(slot.absentReason);

	return (
		<Box
			position={'relative'}
			display={'grid'}
			gridTemplateColumns={'1fr 1fr'}
			gridAutoRows={'1fr'}
			gap={1}
			p={1}
			sx={{ outline: '1px solid gray' }}
			flexShrink={1}
			key={`${slotIdx}-${slot.startTime}-${slot.endTime}`}
		>
			<IconButton
				sx={{ justifySelf: 'end', gridColumn: 'span 2' }}
				onClick={() => {
					const copy = structuredClone(day);
					copy.slots.splice(slotIdx, 1);
					setDay(copy);
				}}
			>
				<Clear />
			</IconButton>
			<DesktopTimePicker
				value={setTime(day.dayDate, slot.startTime)}
				onChange={val => {
					if (!val) return;
					slot.startTime = format(val, 'HH:mm');
					setDay({ ...day });
				}}
			/>
			<DesktopTimePicker
				value={setTime(day.dayDate, slot.endTime)}
				onChange={val => {
					if (!val) return;
					slot.endTime = format(val, 'HH:mm');
					setDay({ ...day });
				}}
			/>
			<CheckboxButton
				checked={!slot.available}
				onChange={checked => {
					slot.available = !checked;
					slot.absentReason = '';
					setDay({ ...day });
				}}
			>
				Frånvarande
			</CheckboxButton>
			{!slot.available && (
				<TextField
					value={absentReason}
					label={'Frånvaroorsak'}
					onChange={val => {
						slot.absentReason = val.target.value;
						setAbsentReason(val.target.value);
						setStaffChanged(true);
					}}
				/>
			)}
		</Box>
	);
}

function AddNewSlotButton(props: {
	day: DaySchedule;
	setDay: (val: DaySchedule) => void;
}) {
	const { day, setDay } = props;

	const onClick = () => {
		day.slots.push({
			startTime: '07:30',
			endTime: '16:00',
			available: true,
			absentReason: '',
		});
		setDay({ ...day });
	};

	return (
		<Button startIcon={<Add />} onClick={onClick}>
			Lägg till tid
		</Button>
	);
}
