import { Button } from '@mui/material';

type CheckboxButtonProps = {
	children?: string;
	checked: boolean;
	onChange: (checked: boolean) => void;
};

export function CheckboxButton(props: CheckboxButtonProps) {
	const { children, checked, onChange } = props;

	const bgColor = checked ? 'primary.main' : undefined;
	const textColor = checked ? '#fff' : 'text.primary';
	const borderColor = checked ? 'primary.dark' : undefined;

	return (
		<Button
			variant={'outlined'}
			sx={{
				backgroundColor: bgColor,
				color: textColor,
				':hover': {
					backgroundColor: bgColor,
					borderColor: borderColor,
				},
			}}
			onClick={() => {
				onChange(!checked);
			}}
		>
			{children}
		</Button>
	);
}
